import { Link } from "react-router-dom";

export default function Privacy() {
    return (
        <>
            <div className="general-layout">
                <div className="page-wrapper">
                    <div className="page-header">
                        Data we collect and usage
                    </div>

                    <div className="page-content">
                        <p>
                            MaiRA asks for User"s personal information (email, demographic location and address, age, name, sex, phone number,
                            preference in partner selection, payment details, personality facets, interests when the User registers with the Site.
                        </p>

                        <p>
                            This information is readily available on the Site and only viewable by the User on logging into the account. The User
                            at all times has the option not to provide this information without agreeing to register with MaiRA.
                        </p>

                        <p>
                            All information collected at the Site to become a Registered User is obtained purely for providing the Services
                            (please review the <Link className="text-white" to="/terms-of-use">Terms and Conditions</Link>) and to improve the quality of your experience from the Services.
                        </p>

                        <p>
                            This privacy Policy discloses the privacy practices of MaiRA applicable to the handling of the information collected at the Site.
                        </p>

                        <p>
                            The User information collected at the Site is of two categories, Personally Identifiable Information and Non-Personally
                            Identifiable Information. Personally Identifiable Information of the User can be specifically used to identify the User
                            as an individual whereas, the Non-Personally Identifiable Information does not identify the User individually. Specifically,
                            MaiRA uses the information collected at the Site for providing high quality Services and suggesting and recommending matches
                            best suited for the User, processing payments, assessing User preference and compatibility aspects. All information collected
                            on the Site could be used to resolve disputes, troubleshoot problems, collect receivables, measure gauge for surveys on User
                            interest in the Site, inform User about promotions and offers, detecting fraud and other criminal activity, to enforce Terms
                            and Conditions and other related policies.
                        </p>

                        <p>
                            By registering with the Site and consenting to Terms and Conditions and related policies at the Site, User agrees and confirms
                            his/ her consent to providing MaiRA this information, which is lawful, necessary and permissible. Our products or services may
                            include third-party products or services. When you use these products or services, they may also collect and use your
                            information to serve you. User at all times has the right to withdraw consent by communicating it to MaiRA or discontinuing
                            use of the Site and Services offered by MaiRA. Upon the User opting outing to withdraw such consent MaiRA will not be under
                            any obligations to provide the Services nor will it be liable for the Services provided till then.
                        </p>

                        <b>
                            The information the User provides at the Site, may come to MaiRA through the following sources:
                        </b>
                        <ol type="1">
                            <li>
                                <u>Log Files.</u> Every time the User visits the Site, MaiRA servers automatically gather information from User browser (such as IP addresses, browser type, Internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks) to analyze trends, administer the site, prevent fraud, track visitor movement in the aggregate, and gather broad demographic information. This information provides an idea of which parts of Site the User visits. Log files are not shared externally.
                            </li>
                            <li>
                                <u>Cookies.</u> "Cookies" are used to keep track of some types of information. These are very small files placed on User"s computer, which allow MaiRA to count the number of visitors to the Site and distinguish repeat visitors from new visitors. Cookies allow MaiRA to save user preferences and track user trends. Please bear in mind if the user browser is set to reject all cookies, the User"s experience may not be optimum and the Site may not function properly. Cookies are not linked to any Personally Identifiable Information and Users who refuse cookies assume all responsibility for any resulting loss of functionality.
                            </li>
                            <li>
                                <u>Web Beacons.</u> "Web beacons" are small transparent graphic images that are often used in conjunction with cookies in order to further personalize the Site for Users and to collect a limited set of information about the visitors to the Site. Web beacons could be used in email communications in order to understand the behavior of customers. Web beacons are not linked to any Personally Identifiable Informati
                            </li>
                            <li>
                                <u>Mobile Analytics.</u> Mobile analytics software could be used to allow MaiRA to better understand the functionality of our mobile software on User phone. This software may record information such as how often the User uses the application, the events that occur within the application, aggregated usage, performance data, and where the application was downloaded from. This information is not linked to any Personally Identifiable Information.
                            </li>
                            <li>
                                <u>Information About the User.</u> When the User is a Registered User or a recipient of the Services, then MaiRA collects a wide variety of information about the User. For the Services, the User has to provide answers to MaiRA Compatibility Assessment. The answers the Users provide is used by the specialists on the panel of MaiRA to prepare a personality profile of the User to match it with other compatible partners. All individual responses to the questions about User personality in the MaiRA Compatibility Assessment is held strictly confidential, while other non-Personally Identifiable Information (like first name, occupation, height, colour of skin and eyes etc.) is used to build the User"s "My Profile". It is possible that details of marital status, recent log-in date and other profile/account information of the User may be displayed to the User"s recommended matches. All photo(s) or video(s) provided by the User as part of profile content, the User agrees and confirms that these may be made available to User"s matches and users of the Services. Except as otherwise stated in this privacy policy, User personally identifiable information is not disclosed to any third party.
                            </li>
                            <li>
                                <u>Payment /Purchase Information.</u> To process payment/ purchases, MaiRA may require User name, address, phone number, email address and credit card information. Such information is used primarily to process User order or as otherwise described herein. MaiRA adheres to strict security guidelines with respect to the storage, possession and handling of such information and protecting it against unauthorized access. It is clarified that MaiRA does not store User"s credit card information. User transaction on MaiRA is authorized and being processed by PayU/ HDFC payment gateway/ any other authorized payment gateway directly without any information passing through MaiRA. Thus, all information related to credit/ debit card is stored/ held with Our authorized payment gateway. Only information relating to credit/ debit card is stored with MaiRA for the cases where Users" are under auto-renewal for the Account and subscription of Services. MaiRA, handles the information only in accordance with the provisions of the applicable law as made applicable from time to time and only for the duration lawfully required, thereunder.
                            </li>

                            <li>
                                <u>E-mails and Telephone Calls.</u> User e-mail address is required while registering for the Services. e-mails are used for both transactional (e.g., confirmation for registration with the Site, credits, notifying of matches) and promotional (e.g., newsletters, new services, special discounts, etc.) purposes.
                            </li>

                            <li>
                                <u>Mobile Device.</u> If User has registered any mobile device or uses the mobile device to access the Site, then device information may get collected (such as mobile device ID, model and manufacturer), operating system and version information, and IP address.
                            </li>
                            <li>
                                <u>Demographic Data.</u> Demographic data could be collected on the Site. This data is used to tailor User"s experience at the Site, showing content that which might interest them, and displaying the content according to their preferences. Some of this information may be shared with advertisers on a non-personally identifiable basis.
                            </li>
                            <li>
                                <u>Online Survey Data.</u> Information may be shared periodically during any voluntary member surveys. Users are encouraged to participate in such surveys as it helps in improving the Services. Survey responses are not linked to personally identifiable information, and all responses are anonymous.
                            </li>
                            <li>
                                <u>Information Regarding User Friends.</u> The Site encourages Users to refer a friend for the Services by sending us a friend"s name and email address. This information remains in our database, and such referred person could be sent a one-time e-mail containing User"s name and inviting them to visit the Site. Such e-mail will also include instructions on how to remove their information from MaiRA database. User agrees not to abuse this feature by entering names and addresses of those who would not be interested in the Services.
                            </li>
                            <li>
                                <u>Public Forums.</u> MaiRA has a blogging facility on the Site and the blogs on the Site may contain posts which are relevant to dating and relationships and is available to Users and visitors to the Site. Any information that is disclosed in the comments section of the blog section becomes public information and User is expected and confirms that the User shall exercise caution when deciding to disclose any Personally Identifiable Information. To request removal of personal information from any blog, User has to contact MaiRA at <a className="text-white" href="mailto:contact@MaiRA.com">contact@MaiRA.com</a>. In some cases, MaiRA may not be able to remove the information from the blog, in which case MaiRA will inform the User of such inability.
                            </li>
                            <li>
                                <u>Use for Research.</u> In addition to the uses outlined above, User agrees to allow MaiRA to anonymously use the information from User experiences to continue our research into successful relationships. This research, conducted by psychologists and behavior research scientists on the panel of MaiRA may be published in academic journals. All responses are kept anonymous, and not published.
                            </li>
                            <li>
                                <u>Use for Verification checks.</u> The User is aware that to continue to receive the Services offered at the Site, it has to undertake the mandatory verification checks. The User agrees that information provided for the verification check is true and correct and is being provided voluntarily with complete knowledge of the purpose of its use by MaiRA and its authorized third party expert Service Provider for such verification. The Verification Check is conducted solely for the purpose of providing a richer experience in the Services. The verification process is kept anonymous and confidential and not shared with any third party other than authorized entities of MaiRA.
                            </li>
                        </ol>

                        <b>Disclosure of Your Information</b>

                        <p>
                            Disclosure to User"s Matches. For providing the Services MaiRA will disclose User profile information and verification results to recommended/ Suggested matches. Photos and other profile information posted by User will be available for viewing. MaiRA could make features available enabling User matches to share User profile information, including photos, with members of their social network(s). User contact information is never shared with User matches.
                        </p>

                        <p>
                            User agrees and confirms that non-abidance of Verification requests made by MaiRA will render the Registered Account at the Site terminable at the instance of MaiRA without any recourse, whatsoever. MaiRA is authorised and justified in taking any action necessary on such refusal by the User.
                        </p>

                        <p>
                            User agrees and understands that TrulyMadly or its third party expert service provider and their associates are not the author
                            or creator of the information coming forth in the Verification Checks. Under this premise and limitations, TrulyMadly or
                            its third party expert service provider or their associates do not warrant accuracy, validity or completeness of the content
                            of the Verification checks. TrulyMadly, expressly disclaims that the Verification checks will meet the User’s aspirations
                            or expectations and shall be performed in an uninterrupted manner. Verification check verifies the existence of the facts
                            and information provided by the User, it does not verify the authenticity of the facts itself.
                        </p>

                        <p>
                            Disclosure By Law. User acknowledges and agrees that MaiRA may disclose information User provides if required to do so by law, at the request of a third party, or if in MaiRA"s sole discretion, believe that disclosure is reasonable to
                        </p>

                        <p>
                            Use of Site and Service. As a User of the Site or a User registered to use any of the Services (a "Registered User"), the
                            User agrees to the following:
                        </p>

                        <ol type="1">
                            <li>
                                Comply with the law, requests or orders from law enforcement, or any legal process (whether or not such disclosure is required by applicable law);
                            </li>

                            <li>
                                Protect or defend MaiRA"s, or a third party"s, rights or property;
                            </li>

                            <li>
                                Protect someone"s health or safety, such as when harm or violence against any person is threatened; or
                            </li>

                            <li>
                                For investigative purposes.
                            </li>
                        </ol>

                        <p>
                            Search Engine. Some portions of User profile may be available to search engine crawlers and therefore may appear in internet search results, but no Personally Identifiable Information will be included in such search results.
                        </p>

                        <p>
                            Information Transferred As a Result of Sale of Business. MaiRA may buy or sell assets and, depending on the transaction, User Personally Identifiable Information may be one of the transferred and acquired assets.
                        </p>

                        <b>
                            Links to or Access from Other Sites
                        </b>

                        <p>
                            Users may be able to access account or content from third party sites, such as social networking sites, by way of various applications. The privacy policies and practices of such sites in connection with information User discloses on such sites may differ from that on the Site. User is encouraged to review the policies and practices of these sites to ensure that the privacy of the information User submits on their site does not conflict with and is consistent with how User wishes this information to be treated. MaiRA is not responsible for the privacy policies or practices or the content of any other websites that may provide access to, or be linked to or from, this Site, including that of any social networking sites and third party advertisers whose offerings require User to navigate away from this Site.
                        </p>

                        <b>
                            Information Sharing
                        </b>

                        <p>
                            MaiRA does not rent, sell or share any personal or sensitive information of User other than as mentioned in this Privacy Policy, with any third party.
                        </p>

                        <b>
                            Opt-out Provisions
                        </b>

                        <p>
                            User has the right to opt-in or opt-out of any marketing/promotional/newsletter mailings. Upon opting out, provisions of this Terms and Conditions and the related policies will become applicable. However, MaiRA reserves the right to send service related communication without offering User the facility to opt-out. User can update your account settings and information at any time. At User"s request, MaiRA can cancel User registration and remove/block personally identifiable information from it"s database. However, this information may remain in archives even after deletion or termination of User account as required by law and the handling of the same is in accordance with the provisions of the applicable law and as intimated by the government agency from time to time.
                        </p>

                        <p>
                            You can contact our support team at <a className="text-white" href="mailto:contact@MaiRA.com">contact@MaiRA.com</a> through your registered email address with a request to delete your data from our records.
                        </p>

                        <b>
                            Security Practices
                        </b>

                        <p>
                            MaiRA follows stringent security techniques and requirements for handling sensitive and personal information. These techniques and requirements are fully compliant with the guidelines set forth under law. MaiRA servers are accessible only to authorized personnel. User information logged under the Terms and Conditions is shared with respective personnel only on need to know basis and/ or for the purpose of completing a transaction and/ or to providing Services. All representatives handling information under these provisions are under contractual confidentiality obligation with MaiRA. While, MaiRA takes every step to safeguard the confidentiality of User personal information, by using the Site, User agrees and confirms that MaiRA is not liable for any disclosure due to errors in transmission or unauthorized acts of third parties.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}