import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

function Header(props) {

    return (
        <React.Fragment>
            <nav className={"navbar position-fixed w-100 " + (props.addBg ? "bg-general" : "")}>
                <div className="header-container">
                    <a className="navbar-brand" href="/">
                        <svg width="120" viewBox="0 0 151 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_501_2048)">
                                <path d="M70.347 12.7369H75.1655V35.3571H71.2595V18.7722L64.4557 31.0029H62.2466L55.4269 18.8042V35.3571H51.5368V12.7369H56.3874L63.3671 25.1597L70.347 12.7369ZM121.975 35.3571L116.148 26.9366H113.186V35.3571H109.296V12.7369H118.693C120.806 12.7369 122.573 13.42 123.992 14.786C125.422 16.1521 126.137 17.8597 126.137 19.9088C126.137 21.6163 125.619 23.0893 124.584 24.3272C123.56 25.5651 122.22 26.3657 120.566 26.7285L126.665 35.3571H121.975ZM113.186 16.4029V23.5269H118.629C119.632 23.5269 120.465 23.1853 121.126 22.5021C121.799 21.8085 122.135 20.9547 122.135 19.9408C122.135 18.9269 121.799 18.0838 121.126 17.4114C120.465 16.7391 119.632 16.4029 118.629 16.4029H113.186ZM146.004 35.3571L144.179 30.5385H133.885L132.044 35.3571H127.85L136.767 12.7369H141.393L150.31 35.3571H146.004ZM135.262 26.9366H142.802L139.024 16.9952L135.262 26.9366Z" fill="white" />
                                <path d="M92.9032 18.6441H96.585V35.3572H92.9032V32.9559C91.4304 34.8236 89.4774 35.7574 87.0439 35.7574C85.4964 35.7574 84.093 35.3786 82.8337 34.6207C81.5743 33.8524 80.5871 32.8012 79.872 31.467C79.1677 30.1331 78.8156 28.6442 78.8156 27.0007C78.8156 25.3571 79.1677 23.8682 79.872 22.5343C80.5871 21.2002 81.5743 20.1543 82.8337 19.3965C84.093 18.6281 85.4964 18.2439 87.0439 18.2439C89.4879 18.2439 91.4411 19.1724 92.9032 21.0294V18.6441ZM87.7644 32.2035C89.2372 32.2035 90.4592 31.7072 91.4304 30.7147C92.4122 29.7114 92.9032 28.4734 92.9032 27.0007C92.9032 25.5172 92.4122 24.2792 91.4304 23.2866C90.4592 22.2834 89.2372 21.7817 87.7644 21.7817C86.2809 21.7817 85.0429 22.2834 84.0504 23.2866C83.0685 24.2792 82.5776 25.5172 82.5776 27.0007C82.5776 28.4734 83.0685 29.7114 84.0504 30.7147C85.0429 31.7072 86.2809 32.2035 87.7644 32.2035ZM102.797 15.8106C102.135 15.8106 101.58 15.5865 101.132 15.1382C100.683 14.69 100.459 14.1404 100.459 13.4893C100.459 12.817 100.683 12.262 101.132 11.8244C101.58 11.3869 102.135 11.1681 102.797 11.1681C103.469 11.1681 104.029 11.3869 104.477 11.8244C104.926 12.262 105.15 12.817 105.15 13.4893C105.15 14.151 104.926 14.706 104.477 15.1542C104.029 15.5918 103.469 15.8106 102.797 15.8106ZM100.955 35.3572V18.6441H104.637V35.3572H100.955Z" fill="#FAE83E" />
                                <path d="M13.4822 42.1755C17.8932 44.7285 23.082 45.5902 28.0813 44.5997C33.0806 43.6095 37.5493 40.8347 40.6539 36.7929C43.7584 32.751 45.2874 27.7179 44.9554 22.632C44.6235 17.5462 42.4532 12.7547 38.8496 9.15079C35.2457 5.54689 30.4545 3.37663 25.3689 3.04463C20.2832 2.71262 15.2504 4.24155 11.2087 7.34636C7.16709 10.4511 4.39245 14.9199 3.40215 19.9194C2.41183 24.9191 3.27343 30.108 5.82633 34.5193L3.08988 42.6896C2.98706 42.9979 2.97213 43.3288 3.04678 43.6451C3.12144 43.9614 3.28271 44.2509 3.51255 44.4806C3.74238 44.7105 4.03166 44.8717 4.34799 44.9464C4.66432 45.021 4.9952 45.0062 5.30353 44.9034L13.4822 42.1755Z" fill="#7357FF" />
                                <path d="M24 35.0632C24 35.0632 12.055 28.6314 12.055 21.0508C12.055 19.4059 12.7085 17.8283 13.8716 16.6652C15.0347 15.5021 16.6123 14.8486 18.2572 14.8486C20.8518 14.8486 23.0743 16.2625 24 18.524C24.9257 16.2625 27.1481 14.8486 29.7427 14.8486C31.3877 14.8486 32.9653 15.5021 34.1284 16.6652C35.2916 17.8283 35.9449 19.4059 35.9449 21.0508C35.9449 28.6314 24 35.0632 24 35.0632Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_501_2048">
                                    <rect width="150.857" height="48" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                    </a>

                    <div className="menu-items">
                        <div className={(props.home ? "active " : '') + 'menu-item'}>
                            <Link to='/'>Home</Link>
                        </div>
                        <div className={(props.terms ? "active " : '') + 'menu-item'}>
                            <Link to="/terms-of-use">Terms of Use</Link>
                        </div>
                        <div className={(props.privacy ? "active " : '') + 'menu-item'}>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </div>
                        <div className={(props.contact ? "active " : '') + 'menu-item'}>
                            <Link to="/contact">Contact</Link>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
}

export default Header;