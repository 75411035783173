import './App.scss';
import Header from './components/Header';
import { Routes, Route, Navigate } from "react-router-dom";
import Home from './pages/Home';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Contact from './pages/Contact';


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<><Header home /><Home/></>} />
        <Route path="/terms-of-use" element={<><Header terms addBg /><Terms/></>} />
        <Route path="/privacy-policy" element={<><Header privacy addBg /><Privacy/></>} />
        <Route path="/contact" element={<><Header contact addBg /><Contact/></>} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
}

export default App;
