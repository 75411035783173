export default function Contact() {
    return (
        <>
            <div className="general-layout">
                <div className="page-wrapper">
                    <div className="page-header">
                        Contact Us
                    </div>

                    <div className="page-content">

                        <p>
                            Got something you want to talk about? Email us and we will get back to you as soon as we can.
                        </p>

                        <p>
                            <b>General or App Related issues</b>
                        </p>

                        <p>
                        For feedback or issues related to the app: <a className="text-white" href="mailto:contact@MaiRA.com">contact@MaiRA.com</a>
                        </p>

                        <p>
                            <b>Address:</b>
                        </p>

                        <p>
                        2-A/3, Kundan Mansion, Asaf Ali Road, New Delhi, India
                        </p>

                        <p>
                        <b>Pin:</b> 110002
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}