import './styles.scss';
import React from 'react';
import backgroundVideo from '../../videos/9df822d9ea.mp4';
import { Link } from 'react-router-dom';

function Home() {

    return (
        <>
            <div className="home-wrapper">
                <video autoPlay muted loop className="video-tag">
                    <source src={backgroundVideo} type="video/mp4" />
                </video>
                <div className="center-banner-section">
                    <div className="text-wrapper col-md-6 p-0">
                        <div className="banner-title">
                            Leap into your fantasies with MaiRA, controlling them according to your ideals.
                        </div>

                        <div className="banner-subtitle">
                            Forge a bond with a virtual AI companion who attentively listens, responds, and cherishes your presence. 
                            Develop relationships and intimacy at your own pace and according to your preferences.
                        </div>

                        <Link to="https://play.google.com/store/apps/details?id=com.friend.android.app" className="banner-button">
                            <img src={require('../../images/google-play.webp')} alt="g-play" />
                        </Link>
                    </div>
                    <div className="img-wrapper col-md-6 p-0">
                        <img width="70%" src={require('../../images/banner-img.webp')} alt="banner-img" />
                    </div>
                </div>
                <div className="bottom-text">
                    © 2023 MaiRA. All rights reserved
                </div>
            </div>
        </>
    );
}

export default Home;